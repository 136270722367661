import React from 'react'
import PostSection from '../PostSection';
import './ActualitesSection.scss';
import Fade from 'react-reveal/Fade';

/**
 * Filter posts by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {posts} object
 */
export const lastTreePosts = (posts, categorieName = '') => {
    const filterPost = [];
    const limit = 3;
    if (posts.length) {
        for (let i = 0; i < limit; i++) {
            let post = posts[i];
            if (categorieName !== '') {
                if (post.categories !== null && post.categories.find(c => c === categorieName)) {
                    filterPost[i] = post;
                }
            } else {
                filterPost[i] = post
            }

        }
    }

    return filterPost;
};

class ActualitesSection extends React.Component {
    static defaultProps = {
        title: '',
        categorieName: '',
        posts: []
    };


    render() {
        const {title, posts, categorieName} = this.props;
        const filterPosts = lastTreePosts(posts, categorieName);
        return (
            <div className="ActualitesSection p-8 text-center">
                <Fade>
                {title && <h2 className="ActualitesSection--Title">{title}</h2>}
                <hr></hr>
                </Fade>
                    <Fade bottom>
                {!!filterPosts.length && (
                    <section className="section">
                        <div className="container">
                            <PostSection posts={filterPosts}/>
                        </div>
                    </section>
                )}
                </Fade>
                <a href={"/actualite-categories/" + categorieName + "/"}><button className="Button mt-4">voir tous les {title}</button></a>
            </div>
        )
    }
}

export default ActualitesSection
